<template>
  <section class="section section__footer" id="section5">
    <div class="section__wrapper">
      <div class="section__footer-items">
        <div class="row">
          <div class="col-sm-6 section__footer-question">
            <div class="title-with-icon mb-md-4 mb-2">
              <div class="title-with-icon__icon title-with-icon__icon_news"></div>
              <div class="title-with-icon__title">Остались вопросы?</div>
            </div>
            <div class="text mb-2 mb-md-4">Специально для этого на нашем сайте работает обратная связь, вы задаете вопрос
              и получаете ответ.
            </div>
            <a href="#" class="link-target" @click.prevent="checkAuth()" data-toggle="modal" data-target="#add-question"><span>Задать вопрос</span></a>
          </div>
          <div class="col-sm-6 section__footer-contacts">
            <div class="title-with-icon mb-md-4 mb-2">
              <div class="title-with-icon__icon title-with-icon__icon_contacts"></div>
              <div class="title-with-icon__title">Контакты</div>
            </div>
            <div class="text">
              <div class="contact-item contact-item_location">123610, Москва,<br/>Краснопресненская наб. 12, подъезд 9
              </div>
              <div class="contact-item contact-item_phone"><a href="tel:+7 (495) 937-4742">+7 (495) 937-4742</a></div>
              <div class="contact-item contact-item_mail"><a href="mailto:info@exportedu.ru">info@exportedu.ru</a></div>
            </div>
          </div>
          <div class="col-sm-6 section__footer-feedback">
            <div class="title-with-icon mb-md-4 mb-2">
              <div class="title-with-icon__icon title-with-icon__icon_feedback"></div>
              <div class="title-with-icon__title">Обратная связь</div>
            </div>
            <div class="text">
              <div class="contact-item contact-item_mail"><a href="mailto:info@exportedu.ru">info@exportedu.ru</a></div>
            </div>
          </div>
        </div>
      </div>
      <div class="section__footer-navigation">
        <a href="/about">О Школе экспорта</a>
        <a href="/education">Обучение</a>
        <a href="/calendar">Календарь обучения</a>
        <a href="/news">Новости</a>
        <a href="/documents">Библиотека</a>
        <a href="/sveden">Сведения об образовательной организации</a>
      </div>
      <div class="section__footer-copyright">
        <div class="row">
          <div class="col-sm-6 section__footer-copyright-text"><p>{{ new Date().getFullYear() }}, Школа экспорта Российского экспортного центра</p></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import $ from "jquery";
import {TokenService} from "../../services/token.service";
window.jQuery = $
export default {
  name: "PageFooter",
  methods: {
    checkAuth() {
      if(!TokenService.getUser()) {
        window.location.href = '/sign-in'
      }
    }
  }
}
</script>

<style scoped>

</style>